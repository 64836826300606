.wrapper {
    width: 100%;
    position: relative;
}

.hints {
    color: black;
    font-size: 16px;
    line-height: 2;
    list-style: none;
    margin-top: -15px;
    padding: 15px 20px;
    width: 100%;
}

.hints {
    animation: maxHeight300 .3s ease-out normal forwards;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 var(--box-shadow-radius) var(--box-shadows);
    overflow-y: auto;
    overscroll-behavior: contain;
    position: absolute;
    top: calc(var(--car-search-input-h) + 23px);
    z-index: 20;
}

.active-hint,
.hint {
    color: inherit;
    cursor: pointer;
    font-weight: bold;
    opacity: .6;
    user-select: none;
}

.active-hint,
.hint:hover {
    opacity: 1;
}

.active-hint {
    background: var(--dark-blue);
    color: #fff;
    margin: 0 -10px 0 -10px;
    padding: 0 10px 0 10px;
}

@keyframes maxHeight300 {
    from { max-height: 0; opacity: .2; }
    to { max-height: 300px; opacity: 1; }
}