.btn {
    align-items: center;
    background: transparent;
    display: flex;
    flex-direction: column;
    height: 40px;
    width: 86px;
    text-shadow: inherit;
}

.btn,
.btn span {
    color: inherit;
}

.active,
.active span {
    color: var(--teal);
}