/*!
 * Hamburgers lib
 * https://github.com/jonsuh/hamburgers
 */

.hamburger {
    display: inline-block;
    cursor: pointer;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    font: inherit;
    color: inherit;
    text-transform: none;
    background-color: transparent;
    border: 0;
    margin: 0;
    margin-left: -10px;
    overflow: visible;
}

.hamburger:hover {
    opacity: 0.7;
}

.hamburger.active:hover {
    opacity: 0.7;
}

.hamburger.active .inner,
.hamburger.active .inner::before,
.hamburger.active .inner::after {
    background-color: var(--teal);
}

.box {
    --width: 30px;

    width: var(--width);
    height: 24px;
    display: inline-block;
    position: relative;
}

.inner {
    display: block;
    top: 50%;
    margin-top: -2px;
}

.inner,
.inner::before,
.inner::after {
    width: var(--width);
    height: 3px;
    background-color: var(--teal);
    border-radius: 4px;
    position: absolute;
    transition-property: transform;
    transition-duration: 0.15s;
    transition-timing-function: ease;
}

.inner::before,
.inner::after {
    content: "";
    display: block;
}

.inner::before {
    top: -10px;
}

.inner::after {
    bottom: -10px;
}


/* Spin */

.spin .inner {
    transition-duration: 0.22s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.spin .inner::before {
    transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}

.spin .inner::after {
    transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}

.spin.active .inner {
    transform: rotate(225deg);
    transition-delay: 0.12s;
    transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}

.spin.active .inner::before {
    top: 0;
    opacity: 0;
    transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}

.spin.active .inner::after {
    bottom: 0;
    transform: rotate(-90deg);
    transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

.menu {
    --width: clamp(280px, 360px, 80vw);
    --m-width: calc(-1 * var(--width));
    background: white;
    border-top: 1px solid var(--box-shadows);
    color: var(--black);
    display: flex;
    flex-direction: column;
    height: calc(100vh - var(--navbar-h));
    left: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 20px 20px 36px 20px;
    position: fixed;
    text-align: left;
    top: var(--navbar-h);
    transform: translateX(var(--m-width));
    width: var(--width);
    will-change: transform;
    z-index: 51;
}

.menu.hidden {
    animation: moveOut .25s ease-out forwards;
}

.menu.open {
    animation: moveIn .25s ease-out forwards;
}

.menu a {
    border: none;
    font-weight: normal;
    padding: 20px 0;
    user-select: none;
}

.overlay {
    background: #000;
    inset: 0;
    opacity: 0;
    pointer-events: none;
    position: fixed;
    will-change: opacity;
    z-index: 50;
}

.overlay.hidden {
    animation: fadeOut .25s ease-out forwards;
    pointer-events: none;
}

.overlay.open {
    animation: fadeIn .25s ease-out forwards;
    pointer-events: all;
}

@keyframes fadeIn {
    from {
        opacity: 0
    }

    to {
        opacity: .4
    }
}

@keyframes fadeOut {
    from {
        opacity: .4
    }

    to {
        opacity: 0
    }
}

@keyframes moveIn {
    from {
        transform: translateX(var(--m-width))
    }

    to {
        transform: translateX(0px)
    }
}

@keyframes moveOut {
    from {
        transform: translateX(0px)
    }

    to {
        transform: translateX(var(--m-width))
    }
}

@media screen and (min-width: 460px) {
    .box {
        --width: 40px;
    }

    .hamburger {
        margin-left: 0;
        padding: 15px;
    }
}