.columns {
    display: flex;
    flex-direction: column;
    height: 100%;
    max-width: 100%;
    width: 100%;
}

.column {
    flex-grow: 1;
}

.column.center,
.column.center * {
    align-self: center;
    text-align: center;
}

.enum {
    position: relative;
}

.enum .lp {
    color: var(--subtle-green);
    display: block;
    font-size: 120rem;
    font-weight: bold;
    inset: 0;
    position: absolute;
    top: -60rem;
    z-index: -1;
}

.narrow {
    flex-direction: row;
    flex-wrap: wrap;
}

.narrow .column {
    flex: 1 0 calc(50% - 20px);
}

@media screen and (max-width: 1260px) {
    .center-mobile-gap * {
        align-self: center;
        text-align: center;
    }

    .columns,
    .center-mobile-gap {
        gap: 40px !important;
    }

    .columns.center-mobile {
        align-items: center;
        gap: 0 !important;
        text-align: center;
    }

    .columns.center-mobile,
    .columns.center-mobile * {
        justify-content: center;
    }
}

/* must be in sync with footer styles */
@media screen and (min-width: 1261px) {
    .columns {
        flex-direction: row;
    }

    .column.center {
        align-self: stretch;
        text-align: left;
    }

    .enum {
        inset: initial;
    }
}

@media screen and (min-width: 921px) {
    .narrow .column {
        flex: 1 0 calc(33% - 24px);
    }
}

@media screen and (min-width: 1051px) {
    .narrow .column {
        flex: 1 1 calc(25% - 15px);
    }
}