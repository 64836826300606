.container {
    display: flex;
    flex-direction: column;
}

.main {
    flex-grow: 1;
}

.sticky {
    --width: clamp(300px, 33%, 400px);

    flex: 0 0 var(--width);
    height: fit-content;
    position: sticky;
    top: var(--navbar-h);
    z-index: 1;
}

.container h2 {
    font-size: 22rem;
    font-weight: normal;
}

@media screen and (min-width: 1051px) {
    .container {
        flex-direction: row;
        gap: 40px;
    }
}