.center {
    text-align: center;
}

.baloon-wrap {
    position: relative;
    width: 100%;
}

.baloon {
    background: var(--subtle-green);
    border-radius: 50%;
    bottom: 0;
    height: 180vw;
    left: -40vw;
    margin-top: 60px;
    position: absolute;
    width: 180vw;
    z-index: -1;
}

.faq details {
    margin-bottom: 26px;
}

.faq summary {
    font-size: 20rem;
    font-weight: bold;
}

.faq details > div {
    color: var(--black) !important;
    font-size: 15px;
}

.heading {
    font-size: 35rem;
    line-height: 2;
    margin: auto;
    text-align: center;
}

.heading > span {
    display: block;
}

.heading-top {
    font-size: 35rem;
    line-height: .9;
    margin-top: 20px;
}

.heading-bottom {
    font-size: 25rem;
}

.subheading {
    color: var(--dark-blue);
    font-size: 16rem;
    margin: auto;
    max-width: 500px;
    text-align: center;
}

.prices {
    align-items: center;
    display: flex;
    flex-direction: column;
    gap: 36px;
    justify-content: center;
    width: 100%;
}

.prices > article {
    width: 400px;
    max-width: 100%;
}

.nav-space {
    margin-top: calc(-1 * var(--navbar-offset));
}

@media screen and (min-width: 1051px) {
    .nav-space {
        margin-top: 0;
    }
}

@media screen and (min-width: 800px) {
    .prices {
        align-items: unset;
        flex-direction: row;
    }
}

@media screen and (min-width: 401px) {
    .heading-top {
        margin-top: 0;
    }
}