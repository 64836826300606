.wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: space-around;
    margin: 32px auto;
    max-width: 500px;
}

.active {
    color: var(--black);
    font-weight: bold;
    text-decoration: underline;
}

.center {
    text-align: center;
}