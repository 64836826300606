.navlink {
    align-items: center;
    border: none;
    color: white;
    cursor: pointer;
    display: flex;
    font-size: inherit;
    font-weight: bold;
    height: 100%;
    justify-content: center;
    margin-bottom: 1px;
    min-height: 56px;
    padding: 0 20px;
}

.teal {
    background: var(--teal);
    /* text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black; */
}

.blue {
    background: var(--dark-blue);
}

.border {
    background: transparent;
    border: 1px solid var(--teal);
    color: var(--teal);
}

.border i {
    color: #000;
}

.disabled {
    background: var(--input-border-c);
    pointer-events: none;
}

.red {
    background: var(--red);
}

.half {
    min-height: calc(var(--navbar-h) / 2);
}

.grow {
    flex-grow: 1;
}

.round_on_mob {
    border-radius: 30px;
}

@media screen and (min-width: 1151px) {
    .round_on_mob {
        border-radius: 0;
    }
}