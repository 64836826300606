.wrapper {
    padding: 20px;
}

.qa_item {
    cursor: pointer;
    margin-bottom: 6px;
}

.qa_item summary {
    line-height: 30px;
    list-style-type: '>  ';
    margin-bottom: 6px;
}

.qa_item[open] summary {
    color: var(--teal);
    font-weight: bold;
    list-style-type: '⌃  ';
}

.qa_item:hover summary,
.qa_item:hover summary b,
.qa_item a {
    color: var(--teal);
    user-select: none;
}

.answer {
    line-height: 1.5;
    margin-left: 21px;
    max-width: 1200px;
}

.answer table {
    border-collapse: collapse;
}

.answer td {
    border: solid 1px var(--input-border-c);
    padding: 0 10px;
    text-align: center;
}

.wrapper h2 {
    text-align: center;
}