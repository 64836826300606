.account {
    color: var(--black);
    display: block;
    margin-bottom: 20px;
}

.menu,
.mobile {
    color: var(--black);
    display: flex;
}

.mobile {
    flex-direction: column;
}

.menu {
    animation: fadeIn .3s ease-out;
    border-radius: var(--border-radius);
    box-shadow: -1px -1px var(--shadow-radius) var(--box-shadows);
    gap: 20px;
    padding: 60px 30px;
    position: absolute;
    right: -6px;
    top: calc(var(--navbar-h) - 15px);
    z-index: 55;
}

[data-navdown] .menu {
    right: calc((100vw - var(--n-container-wdt)) / 2);
}

.mobile,
.menu {
    background: white;
}

.mobile {
    padding-left: 30px;
}

.mobile > a {
    font-weight: normal;
    padding: 20px 0;
}

.mobile > span {
    margin: 0;
}

.mobile > span > b {
    font-weight: normal;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}