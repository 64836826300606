.icon {
    position: relative;
}

.icon i {
    color: var(--teal);
    left: 2px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

.icon input {
    padding-left: 38px !important;
}