.slider-offset {
    display: none;
}

.header-h {
    font-size: clamp(26rem, 3vw, 56rem);
    line-height: 1.2;
    margin-top: 10px;
}

.header-slide {
    align-items: center;
    display: flex;
    gap: 40px;
    max-width: 100%;
    min-height: 300px;
    overflow-x: clip;
    width: 100vw;
}

.header-slide-circle {
    --circle-size: clamp(180px, 55vw, 1000px);

    height: var(--circle-size);
    width: var(--circle-size);
    right: -30%;
    top: -18vw;
}

.header-a {
    margin-left: auto;
    max-width: 650px;
    pointer-events: all;
}

.first-slide-bg {
    --optimal-width: calc(50vw + 16%);
    width: clamp(60px, var(--optimal-width), 1180px);
    right: -30% !important;
    z-index: -1;
}

.firm-base-bg {
    border-radius: clamp(20px, 25vw, 300px) 0 0 0;
    max-width: 50vw;
    overflow: clip;
    top: 0;
    z-index: 1;
}

.cflex {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.cmargin {
    margin-inline: auto;
}

.ctext {
    text-align: center;
}

.ltext {
    text-align: left !important;
}

.m0 {
    margin: 0;
}

.bg-wrapper {
    position: relative;
}

.bg-wrapper-img {
    height: 100%;
    object-fit: cover;
    position: absolute;
    width: 100%;
}

.dat {
    display: flex;
	justify-content: flex-end;
	padding-right: 120px;
	width: 100%;
}

.dat-inner {
    align-items: center;
    display: flex;
    flex-direction: column;
    text-align: center;
}

.dat-h {
    font-size: 40rem;
    font-weight: normal;
    margin-bottom: 16px;
}

.dat-h span {
    color: var(--black);
    font-weight: bold;
}

@media screen and (max-width: 760px) {
    .header-slide {
        min-height: auto;
    }
}

@media screen and (min-width: 421px) {
    .header-slide-circle {
        right: -9vw;
    }

    .first-slide-bg {
        right: -16% !important;
    }
}

@media screen and (min-width: 1051px) {
    .header-slide {
        min-height: 326px;
    }

    .slider-offset {
        display: block;
        height: calc(var(--navbar-h) + var(--navbar-offset));
    }

    .bg-wrapper section {
        padding-inline: 20px;
    }
}