.wrapper {
    align-items: center;
    display: flex;
    margin: auto;
    padding: 12px 0;
}

.icon {
    cursor: pointer;
    display: flex;
}

.icon > .inner {
    position: relative;
    max-width: 36px;
}

.inner > span {
    background: rgba(255, 255, 255, .92);
    border: 1px solid var(--dark-blue);
    border-radius: 50%;
    bottom: -5px;
    display: block;
    font-size: 10rem;
    font-weight: bold;
    height: 20px;
    line-height: 18px;
    pointer-events: none;
    position: absolute;
    right: 0px;
    text-align: center;
    width: 20px;
}

.icon,
.icon > .inner,
.inner > span {
    color: var(--black);
    user-select: none;
}
