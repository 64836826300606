.sent,
.received {
    animation: fadein .3s normal forwards ease-in-out;
    border-radius: 12px;
    display: table;
    margin: 16px 0;
    min-width: 286px;
    max-width: 100%;
    padding: 16px 20px;
    position: relative;
    white-space: pre-line;
}

.sent:last-child,
.received:last-child {
    margin-bottom: 35px;
}

.sent {
    justify-self: flex-start;
    background: #b2d8d8;
    margin-left: 12px;
}

.received {
    margin-left: auto;
    background: #E0EEEE;
    margin-right: 12px;
}

.sent::after,
.received::after {
    border: 12px solid transparent;
    border-bottom: 0;
    content: '';
    height: 0;
    margin-top: -6px;
	position: absolute;
	top: 50%;
	width: 0;
}

.sent::after {
    border-left: 0;
    border-right-color: #b2d8d8;
    left: 0;
	margin-left: -12px;
}

.received::after {
    border-left-color: #E0EEEE;
	border-right: 0;
	margin-right: -12px;
    right: 0;
}

.info {
    font-size: 10rem;
    position: absolute;
    right: 6px;
    top: -13px;
}

.date {
    opacity: .5;
}

.who {
    margin-left: 7px;
}

.text a {
    text-decoration: underline;
}

@keyframes fadeIn {
    0% { opacity: 0; }
    100% { opacity: 1; }
}