.navbar {
    --gap: 12px;
    --radius: var(--border-radius);
    --shadow-radius: var(--box-shadow-radius);

    align-items: center;
    background-color: white;
    border-radius: 0;
    box-shadow: 0 0 var(--shadow-radius) var(--box-shadows);
    color: var(--black);
    display: flex;
    gap: var(--gap);
    height: var(--navbar-h);
    left: 50%;
    max-width: 100%;
    padding-inline: var(--container-padding);
    position: fixed;
    top: 0;
    transition: transform .2s ease-out;
    width: 100%;
    z-index: 21;
}

.no-shadow {
    box-shadow: none;
}

.logo {
    margin-bottom: -3px;
    margin-right: auto;
}

.logo,
.logo img {
    width: 130px;
}

.visible {
    transform: translate(-50%, 0px);
}

.hidden {
    transform: translate(-50%, calc(-1 * (var(--navbar-h) + var(--navbar-offset))));
}

.containButton>[data-button] {
    width: 100%;
    height: 0px;
}

.navbar>a[class*="button"] {
    font-size: 10rem;
    padding-inline: 11px !important;
    text-align: center;
}

/* .navbar>a[class*="button"]>span {
    font-size: 10px;
} */

@media screen and (min-width: 340px) {
    .navbar>a[class*="button"] {
        font-size: 14rem;
        max-width: 120px;
        padding-right: 26px !important;
    }
}

@media screen and (min-width: 425px) {

    .logo,
    .logo img {
        width: 178px;
    }
}

@media screen and (min-width: 500px) {
    .navbar>a[class*="button"] {
        font-size: 15rem;
        max-width: none;
    }
}

@media screen and (min-width: 1051px) {
    .navbar {
        border-radius: var(--radius);
        top: var(--navbar-offset);
        width: var(--n-container-wdt);
    }

    .navbar>a[class*="button"] {
        max-width: none;
        padding-inline: 16px !important;
    }

    .nav-down {
        --pad: calc((100vw - var(--n-container-wdt)) / 2);

        border-radius: 0;
        padding-inline: max(20px, var(--pad));
        top: 0;
        width: 100%;
    }

    .no-shadow {
        box-shadow: 0 0 var(--shadow-radius) var(--box-shadows);
    }
}