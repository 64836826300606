.list {
    background: rgba(255,255,255,.9);
    font-size: 12rem;
    max-height: 100%;
    max-width: 220px;
    overflow-y: auto;
    padding: 6px;
    position: absolute;
    right: 0;
    top: 107px;
}

.active,
.inactive {
    align-items: center;
    cursor: pointer;
    display: flex;
    padding: 4px 6px;
}

.active {
    background: var(--gray);
}

.avatar {
    border-radius: 50%;
    height: 36px;
    margin-right: 10px;
    overflow: hidden;
    position: relative;
    width: 36px;
}

.avatar img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.avatar span {
    align-items: center;
    background: var(--dark-blue);
    color: white;
    display: flex;
    font-size: 16rem;
    height: 100%;
    justify-content: center;
    object-fit: cover;
    width: 100%;
}

.list button {
    font-weight: 100;
    height: 30px;
    min-height: 30px;
}