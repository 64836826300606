.input-grid {
    display: grid;
    gap: 20px;
}

.full-width {
    grid-column: 1 / -1;
}

.flex-distributed {
    align-items: center;
    display: flex;
    flex-wrap: wrap;
    gap: 12px;
    justify-content: space-between;
}

.input-grid label {
    color: var(--black);
}

.input-grid input[readonly] {
    background: var(--gray);
}

.input-grid label[data-required]::after {
    content: ' *';
    color: var(--red);
}

@media screen and (min-width: 1112px) {
    .input-grid {
        grid-template-columns: repeat(3, 1fr);
    }

    .input-grid.four {
        grid-template-columns: repeat(4, 1fr);
    }
}