.wrapper {
    background-color: var(--gray);
    height: 0;
    padding-top: 66%;
    position: relative;
    width: 100%;
}

.inactive {
    display: none;
}

.active {
    animation: fadeIn 1s ease-out;
    display: block;
}

@keyframes fadeIn {
    from { opacity: 0; }
    to { opacity: 1; }
}