.list {
    flex-grow: 1;
    overflow-y: auto;
    overscroll-behavior: contain;
}

.list button {
    font-weight: 100;
    height: 36px;
    min-height: 30px;
    padding-bottom: 2px;
    user-select: none;
}