.aux-slot {
    align-items: flex-end;
    display: flex;
    gap: 12px;
    width: 100%;
}

.aux-slot>* {
    flex: 1 1 auto;
}

.aux-slot>div:nth-child(n+1) {
    flex-basis: 60%;
}

.aux-slot>div:nth-child(n+2) {
    flex-basis: 40%;
}