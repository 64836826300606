.footer {
    padding: 40px 20px;
    position: relative;
}

.footer,
.footer hr {
    display: block;
    margin: 0;
}

.footer h3 {
    margin-bottom: 29px;
}

.footer a {
    display: block;
    margin-bottom: 12px;
}

.h3-breaker {
    display: none;
}

.footer h3,
.footer a {
    user-select: none;
}

.footer div div:first-child {
    margin-top: 26px;
    order: 4;
    width: 100%;
}

.footer hr {
    position: relative;
    top: -40px;
}

/* must be in sync with columns styles */
@media screen and (min-width: 1261px) {
    .footer div div:first-child {
        margin-top: 0;
        order: 0;
        width: initial;
    }

    .h3-breaker {
        display: block;
        height: 73px;
    }
}