.on,
.disabled {
    position: relative;
}

.disabled input {
    background: var(--gray);
    pointer-events: none;
}

.pad input {
    padding-right: 72px;
}