.input-hint-wrapper,
.input-outer,
.wrap {
    position: relative;
    width: 100%;
}

.hints {
    animation: maxHeight300 .3s ease-out normal forwards;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 var(--box-shadow-radius) var(--box-shadows);
    font-size: 16rem;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-block: 11px;
    position: absolute;
    top: calc(var(--car-search-input-h) + 23px);
    min-width: 100%;
    z-index: 20;
}

.hints p {
    cursor: pointer;
    margin: 0;
    padding-block: 11px;
    padding-inline: 20px;
}

.input {
    border: none;
    color: var(--teal);
    height: var(--car-search-input-h);
    margin-top: 4px;
    outline: none;
    padding: 0;
}

.input:placeholder-shown {
    color: var(--black);
}

.solid {
    --height: 44px;
}

.input-hint-wrapper .search .input,
.solid .input {
    background: var(--white);
    border: 1px solid var(--box-shadows);
    border-radius: 8px;
    height: var(--height);
    padding-inline: 8px 20px;
    width: 100%;
}

.clearable input {
    padding-right: 54px !important;
}

.input,
.input-outer {
    width: 100%;
}

.range-like-input-body,
.range-input-body {
    align-items: center;
    display: flex;
    gap: 12px;
    width: 100%;
}

.range-input-body span {
    align-items: center;
    display: flex;
    flex-grow: 1;
    gap: 3px;
}

.range-input-body .hints {
    top: calc(var(--car-search-input-h) + 4px);
}

.icon {
    right: 0;
}

.clear {
    cursor: pointer;
    right: 30px;
}

.clear,
.icon {
    --icon-size: 18rem;

    bottom: 10px;
    position: absolute;
}

.clear i,
.icon i {
    font-size: var(--icon-size);
}

.solid .clear,
.solid .icon {
    bottom: calc((var(--height) - var(--icon-size)) / 2);
}

.label {
    color: var(--black);
    display: block;
}

.disabled {
    pointer-events: none;
}

.disabled .input,
.disabled.search .input {
    background: var(--gray) !important;
}

.label {
    color: inherit;
    cursor: pointer;
}

.input-hint-wrapper .search {
    margin-bottom: 12px;
}

.range-like-input-body .search {
    margin-bottom: 0;
}

.input-hint-wrapper .search .label {
    display: none;
}

.input-hint-wrapper .search .input {
    --height: 40px;
}

/* .input-hint-wrapper .search .input,
.range-input-body .input,
.range-like-input-body .input {
    background: var(--gray);
} */

.range-like-input-body div:first-child {
    flex: 0 0 55%;
}

.input-hint-wrapper .search input,
.input-hint-wrapper .search input::placeholder {
    font-weight: bold;
}

.input-hint-wrapper .search input::placeholder {
    color: var(--black);
}

.selected {
    background: var(--teal);
    color: #fff;
}

@keyframes maxHeight300 {
    from {
        max-height: 0;
        opacity: .2;
    }

    to {
        max-height: 300px;
        opacity: 1;
    }
}