.btn {
    height: 36px;
    margin-left: auto;
    padding-inline: 16px;
    opacity: 0.86;
}

.h3 {
    margin-bottom: 10px;
    margin-top: 0;
}

.item {
    align-items: center;
    display: flex;
    justify-content: space-between;
    line-height: 1.6;
    margin-bottom: 10px;
    max-width: 360px;
}

.h {
    display: block;
    font-size: 15rem;
    margin-bottom: 18px;
    text-align: center;
}