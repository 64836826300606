.tags {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    gap: 8px;
    margin-right: -20px;
    overflow-y: auto;
    width: calc(100% + 20px);
}

.tags:not(:empty) {
    margin-bottom: 12px;
}

.tags::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.all,
.tag {
    color: var(--black);
    cursor: pointer;
    padding: 9.75px 12px;
    text-wrap: nowrap;
    white-space: nowrap;
}

.tag {
    background: var(--promo-green);
    border: 1px solid var(--teal);
    border-radius: 8px;
    cursor: pointer;
    padding-right: 23px;
    position: relative;
}

.tag::after {
    content: 'close';
    display: block;
    font-family: 'Material Icons';
    font-size: 12px;
    position: absolute;
    right: 5px;
    top: 5px;
}

.shake {
    animation: shake 0.2s ease;
}

@keyframes shake {
    0% {
        transform: rotate(0deg);
    }

    25% {
        transform: rotate(5deg);
    }

    50% {
        transform: rotate(0deg);
    }

    75% {
        transform: rotate(-5deg);
    }

    100% {
        transform: rotate(0deg);
    }
}

@media screen and (min-width: 1051px) {
    .tags {
        flex-wrap: wrap;
        margin-right: unset;
        overflow-y: auto;
        width: 100%;
    }
}