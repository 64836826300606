.grid-item,
.list-item {
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 var(--box-shadow-radius) var(--box-shadows);
    height: 100%;
    overflow: hidden;
    width: 100%;
}

.grid-item,
.list-item,
.body {
    display: flex;
    flex-direction: column;
}

.highlighted {
    background: var(--promo-green);
    border: 1px solid lightgray;
}

.image {
    height: fit-content;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    width: 100%;
}

.image>span {
    display: block !important;
}

.body {
    --padding: 20px;
    --h-padding: calc(var(--padding) / 2 + 2px);

    flex-grow: 1;
    padding: 0 var(--padding) var(--h-padding) var(--padding);
}

.list-item .body .part-title>span:first-child,
.list-item .body .title>span:first-child {
    max-width: 450px;
    word-break: break-all;
}

.body .details {
    align-items: center;
    flex-wrap: wrap;
    margin-top: auto;
}

.body .title,
.body .part-title,
.body .details {
    align-items: flex-start;
    display: flex;
    gap: 20px;
    justify-content: space-between;
    text-align: start;
}

.body .title,
.body .part-title {
    font-size: 19rem;
    gap: 2px;
    margin-bottom: 6px;
}

.body .part-title {
    font-size: 16rem;
}

.body .price {
    color: var(--teal);
    font-weight: bold;
    font-size: 22rem;
}

.body .installment {
    color: var(--black);
    margin-top: 8px;
}

.body .equipment,
.body .firm,
.body .location {
    align-items: center;
    color: var(--black);
    display: flex;
    font-weight: bold;
    font-size: 12px;
    gap: 2px;
    opacity: .8;
    text-align: left;
    word-break: break-word;
}

.body .firm {
    margin-top: auto;
}

.body .equipment {
    font-size: 12px;
    margin-block: 12px;
}

.body .title span,
.body .part-title span {
    color: var(--black);
}

.badge {
    background: var(--blue);
    border-radius: 3px;
    color: var(--white) !important;
    font-size: 12rem;
    margin-top: 16px;
    padding: 4px 12px;
}

.grid-item .badge {
    margin-top: 0;
}

/* [data-is-small] .body .title {
    font-size: 16rem;
} */

[data-is-small] .body .price {
    font-size: 18px;
}

.list-item {
    flex-direction: row;
    max-height: 220px;
}

.list-item .image {
    --width: 300px;

    width: var(--width);
}

.list-item .body .details {
    margin-top: initial;
}

.list-item .body .price {
    font-size: 26rem;
}

.list-body {
    align-items: flex-start;
    display: flex;
    height: 100%;
    justify-content: space-between;
    margin-bottom: auto;
}

.list-body .installment,
.list-body .location {
    text-align: right;
}

.list-body .details {
    padding-top: 19px;
}

.row {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.title span.teal {
    color: var(--teal);
}

.price-col {
    align-items: flex-end;
    display: flex;
    flex-direction: column;
    height: 100%;
    text-align: right;
}

.list-item .offer-title {
    max-width: 68%;
}

.list-item .body:not(.buy) {
    max-width: calc(100% - 300px);
}