.bar {
    border-radius: 3px;
    height: 2px;
    overflow: hidden;
    position: relative;
    top: 0;
    width: 100%;
}

.bar > div {
    background: var(--teal);
    height: 100%;
    position: absolute;
    transition: width 0.5s;
    width: 100%;
    will-change: width;
}
