.article-bg {
    top: 160px;
    max-width: 50vw;
    z-index: 1;
}

.article-mb {
    --width: 70vw;

    margin-left: calc(-1 * (100vw - var(--width)));
    position: static !important;
    width: var(--width);
}