.label {
    color: var(--black);
    display: flex;
    flex-direction: column;
    font-weight: bold;
    gap: 4px;
}

.select {
    align-items: center;
    background: var(--white);
    border: 1px solid var(--box-shadows);
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    height: 44px;
    margin-bottom: 12px;
    padding-inline: 8px 20px;
    position: relative;
    width: 100%;
}

.label * {
    font-weight: normal;
}

.select::after {
    content: 'expand_more';
    font-family: 'Material Icons';
    position: absolute;
    right: 8px;
}

.options {
    animation: maxHeight300 .3s ease-out normal forwards;
    background: var(--white);
    border-radius: var(--border-radius);
    box-shadow: 0 0 var(--box-shadow-radius) var(--box-shadows);
    overflow-y: auto;
    overscroll-behavior: contain;
    padding: 12px;
    position: absolute;
    top: calc(var(--car-search-input-h) + 8px);
    z-index: 20;
}

.select .options>div {
    cursor: pointer;
    padding-block: 14px;
}

.select.with-icon,
.select.with-icon .options>div {
    padding-left: 38px;
    position: relative;
}

.icon {
    left: 6px;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 26px;
}

.options>div .icon {
    left: 0;
}

@keyframes maxHeight300 {
    from {
        max-height: 0;
        opacity: .2;
    }

    to {
        max-height: 300px;
        opacity: 1;
    }
}