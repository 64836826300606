.section {
    --vert-pad: 40px;

    width: 100vw;
    overflow-x: clip;
    padding: var(--vert-pad) 0;
    position: relative;
}

.gray {
    background: var(--subtle-gray-alt);
}

.green {
    background: var(--subtle-green-alt);
}

.greenAlt {
    background: var(--subtle-green);
}

.white {
    background: var(--white);
}

.background {
    position: absolute;
}

.right {
    right: 0;
}

.left {
    left: 0;
}

.circle {
    background: var(--gray);
    border-radius: 50%;
    position: absolute;
    z-index: -1;
}

.box {
    padding: var(--vert-pad);
}

.bg-section {
    overflow: clip;
    position: relative;
}

.bg-section * {
    color: var(--white) !important;
}

.bg-section-img {
    display: block;
    inset: 0;
    left: -1px;
    filter: brightness(.8);
    min-height: 100%;
    min-width: calc(100% + 1px);
    z-index: -1;
}

.hr {
    margin-inline: calc(-1 * var(--vert-pad));
    width: calc(100% + 2 * var(--vert-pad));
}

@media screen and (max-width: 400px) {
    .section {
        --vert-pad: 20px;
    }

    .box {
        margin-inline: -20px;
    }
}