.tab-links {
    display: flex;
    gap: 32px;
    overflow-x: auto;
    scroll-behavior: smooth;
    scroll-snap-align: left;
    scroll-snap-type: mandatory;
}

.tab-links .btn {
    border-bottom: 1px solid transparent;
    padding: 3px 2px;
    white-space: nowrap;
}

.tab-links .btn.active {
    border-color: var(--teal);
    color: var(--teal);
}

.tab-links::-webkit-scrollbar {
    background: var(--subtle-gray);
    height: 1px;
}

.tab-links::-webkit-scrollbar-thumb {
    background: var(--box-shadows);
}