.txt,
.reserved,
.big {
    color: #fff;
    font-family: Arial;
    font-size: 30rem;
    font-weight: bold;
    letter-spacing: 1px;
    margin: 0;
    text-align: center;
    top: 10px;
    position: absolute;
    text-shadow: -1.5px 0 #000, 0 1.5px #000, 1.5px 0 #000, 0 -1.5px #000;
    width: 100%;
    z-index: 1;
}

.reserved+.big,
.reserved+.txt {
    top: 42px;
}

@media screen and (min-width: 460px) {
    .big {
        font-size: 58rem;
    }
}