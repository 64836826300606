.wrap,
.wrap span {
    align-items: center;
    display: flex;
}

.wrap {
    --bpad: 0px;
    --rpad: 0px;

    gap: 12px;
}

.wrap span {
    flex-grow: 1;
    gap: 3px;
    position: relative;
}

.unit {
    bottom: 10px;
    position: absolute;
    right: 4px;
}

.with-unit i:first-of-type {
    display: none;
}