.section {
    align-items: center;
    display: flex;
    justify-content: center;
}

.wrap {
    margin-block: 50px;
    text-align: center;
}

.h {
    margin-top: 0;
}

.btns {
    display: flex;
    flex-wrap: wrap;
    gap: 20px;
    justify-content: center;
}

.img {
    max-width: 500px;
    width: 100%;
}

.teal {
    color: var(--teal);
}