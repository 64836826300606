.wrap {
    position: relative;
}

.wrap h1 {
    margin-top: 0;
    text-decoration: line-through;
}

.sign {
    color: var(--red);
    font-size: 38rem;
    font-weight: bold;
    text-transform: uppercase;
}