.spec {
    display: flex;
    gap: 20px;
    margin-bottom: 8px;
}

.name {
    flex: 0 0 160px;
    opacity: .6;
}

.value {
    color: var(--black);
    flex: 1 1 auto;
    overflow: hidden;
    word-break: break-word;
}

.value a {
    text-decoration: underline;
    text-decoration-color: var(--teal);
    text-decoration-thickness: 1px;
    text-underline-offset: 3px;
}

.name,
.value {
    text-align: left;
    font-size: 15rem;
}

.service {
    flex-direction: column;
}

.logo {
    align-items: center;
}

.service,
.logo {
    display: flex;
    gap: 6px;
}

.service:not(:last-child) {
    margin-bottom: 16px;
}

.vinbutton:hover {
    background: white;
}

.path:not(:last-of-type)::after {
    color: var(--dark-blue);
    content: ' > ';
    display: inline-block;
    margin-inline: 6px;
    opacity: .6;
}

@media screen and (min-width: 580px) {
    .service {
        align-items: center;
        flex-direction: row;
    }

    .spec {
        margin-bottom: 8px;
    }
}

@media screen and (min-width: 1051px) {
    .name {
        flex: 0 0 40%;
        text-align: right;
    }
}