.inner:not(:last-child) {
    margin-bottom: 50px;
}

.appendix {
    padding-bottom: 50px;
}

.item {
    display: flex;
}

.item:not(:last-child) {
    margin-bottom: 4px;
}

.content,
.item {
    font-size: 16rem;
    font-weight: 400;
    max-width: 800px;
    overflow-wrap: break-word;
    white-space: pre-line;
    word-wrap: break-word;
}

.name {
    min-width: 170px;
}

.value {
    color: var(--teal);
}