.wrapper {
    --offset: 20px;

    position: relative;
}

.slider {
    display: flex;
    overflow-x: scroll;
    overflow-y: hidden;
    pointer-events: none;
    scroll-behavior: smooth;
    scroll-snap-type: mandatory;
    scroll-snap-align: start;
    width: 100%;
}

.slide {
    flex: 1 0 100%;
    height: 100%;
    width: 100%;
}

.control {
    align-items: center;
    background: none;
    border: none;
    cursor: pointer;
    display: flex;
    justify-content: center;
    pointer-events: all;
    user-select: none;
}

.left-control {
    left: var(--offset);
}

.right-control {
    transform: rotate(180deg);
    right: var(--offset);
}

.left-control,
.right-control {
    --size: 60px;

    background: rgba(255, 255, 255, .9);
    border-radius: calc(var(--size) / 2);
    box-shadow: 0 0 var(--box-shadow-radius) var(--box-shadows);
    height: var(--size);
    position: absolute;
    top: calc(50% - var(--size) / 2);
    width: var(--size);
}

.left-control:hover,
.right-control:hover {
    background: #fff;
}

.dot-controls {
    align-items: center;
    display: flex;
    gap: 12px;
    justify-content: center;
}

.dot-control {
    --size: 15px;

    background: #dcdcdc;
    border-radius: calc(var(--size) / 2);
    height: var(--size);
    position: relative;
    width: var(--size);
}

.dot-control.active {
    background: var(--teal);
}

.slider {
    scrollbar-width: none;
    -ms-overflow-style: none;
}

.slider::-webkit-scrollbar {
    width: 0;
    height: 0;
}