.buttons,
.button,
.per-page {
    align-items: center;
    display: flex;
}

.buttons {
    flex-wrap: wrap;
    justify-content: flex-end;
    margin: 16px 0;
}

.buttons.center {
    justify-content: center;
}

.button {
    --size: 36px;

    border: 2px solid transparent;
    border-radius: 50%;
    font-size: 15rem;
    height: var(--size);
    justify-content: center;
    margin-inline: 3px;
    user-select: none;
    width: var(--size);
}

.current {
    border: 2px solid var(--teal);
    color: var(--teal);
    font-weight: bold;
}

.disabled {
    opacity: .5;
}

.button i {
    font-size: 28rem;
}

.per-page {
    gap: 6px;
    margin-right: auto;
}

.per-page span {
    color: var(--black);
}

.per-page a {
    background: var(--white);
    border-radius: 6px;
    padding: 8px;
}

.per-page .active {
    background: transparent;
    color: var(--teal);
    pointer-events: none;
}