.input {
    height: var(--car-search-input-h);
    padding: 1px 14px
}

.action_input {
    padding: 1px 30px 1px 14px;
}

.unit_input {
    padding: 1px 58px 1px 14px;
}

.textarea {
    min-height: 200px;
    padding: 10px;
    resize: none;
}

.input,
.textarea {
    border: 1px solid var(--box-shadows);
    border-radius: 10px;
    color: inherit;
    margin-top: 4px;
    width: 100%;
}

.label_text {
    color: var(--black);
    min-width: 78px;
}

.required::before,
.label_text[required]::before {
    color: var(--red);
    content: '*';
    margin-right: 6px;
}

.label_small {
    font-size: 12rem;
    margin-left: -3px;
    margin-right: 3px;
}

.action_wrapper {
    position: relative;
    width: 100%;
}

.clear_action,
.unit {
    position: absolute;
    top: 0;
}

.clear_action {
    right: 4px;
}

.unit {
    font-size: 12rem;
    line-height: 1.6;
    right: 28px;
}

.clear_action > i {
    color: black;
    cursor: pointer;
    font-size: 17rem;
}

.pos {
    width: 100%;
}

.disabled {
    opacity: .4;
    pointer-events: none;
}

.resize {
    resize: vertical;
}