.narrow,
.empty {
    align-items: center;
    display: flex;
    justify-content: center;
    padding: 20vh 0;
}

.narrow {
    padding: 10vh 0;
}