.details {
    background: var(--white);
    border: 1px solid var(--box-shadows);
    border-radius: var(--border-radius);
    padding: 20px;
}

.name {
    text-transform: uppercase;
    font-size: 16rem;
    margin-block: 12px;
}

.details > span {
    display: block;
}

.details [icon] {
    margin-block: 6px;
    text-decoration: underline;
}

.details [icon] i {
    font-size: 18rem;
    margin-bottom: 6px;
    margin-left: 0;
}

