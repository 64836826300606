.price {
    display: block;
    font-size: 30rem;
    font-weight: bold;
    margin-block: 24px;
}

.price,
.currency {
    color: var(--teal);
}

.currency {
    font-weight: normal;
    font-size: 20rem;
}

.price b {
    display: block;
    font-size: 14rem;
    font-weight: normal;
    margin-block: 6px;
    opacity: .8;
}

.price b span {
    color: var(--teal);
}

.price b span:not(:last-of-type)::after {
    content: '·';
    display: inline-block;
    margin-inline: 10px;
}