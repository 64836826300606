.btn {
    background-color: var(--dark-blue);
    border: none;
    color: white;
    cursor: pointer;
    font-size: 15px;
    margin: auto;
    margin-bottom: 10px;
    padding: 9px 10px;
    text-align: center;
    width: fit-content;
}

.teal {
    background-color: var(--teal);
}

.un {
    color: var(--red);
}

.teal.un {
    background: var(--red);
    color: white;
}