.links a {
    display: inline-block;
    font-size: 12rem;
}

.links a:not(:last-child) {
    margin-bottom: 16px;
    margin-right: 26px;
}

.links b {
    color: #999;
}