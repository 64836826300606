.lookup-path {
    cursor: pointer;
    display: flex;
    flex-wrap: wrap;
    opacity: .9;
    padding-block: 8px;
}

.lookup-path>span:not(:last-of-type)::after {
    content: '>';
    opacity: .4;
    padding-inline: 8px;
}

.back {
    padding-block: 8px;
}

.pick,
.picked {
    padding-block: 20px;
}

.back i,
.pick i,
.picked i {
    font-size: 18px;
}

.pick:not(:last-of-type) {
    border-bottom: 1px solid var(--box-shadows);
}

.picked i {
    color: var(--teal);
}

.back {
    background: #fafafa;
    margin-bottom: 12px;
    margin-inline: -40px;
    padding: 20px 30px;
}