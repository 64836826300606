.input {
    background: #4dcbc6 !important;
    border: none !important;
    color: #fff !important;
    padding: 0 !important;
    text-align: center;
}

.cinput {
    align-items: center;
    grid-template-columns: 1fr 100px;
}

.btn-wrap {
    align-items: center;
    display: flex;
    gap: 20px;
    flex-wrap: wrap;
}

/* for price wrapping only */
.btn-wrap a {
    margin-top: -30px;
    margin-bottom: 20px;
}

@media screen and (min-width: 395px) {
    .btn-wrap a {
        margin-top: 0;
        margin-bottom: 0;
    }
}
/* ^ for price wrapping only */

@media screen and (min-width: 801px) {
    .cinput {
        grid-template-columns: 161px 1fr 100px;
    }

    .cwrap {
        align-items: center;
        display: grid;
        gap: 20px;
        grid-template-columns: 180px 1fr 130px;
    }
}