.wrap {
    text-align: left;
}

.img-wrap,
.img-wrap-decorative {
    height: 400px;
    max-height: 100%;
    max-width: 100%;
    width: 400px;
}

.img-wrap img {
    object-fit: contain;
}

.img-wrap-decorative img {
    object-fit: cover;
}

.img {
    height: 100%;
    width: 100%;
}

@media screen and (min-width: 1261px) {
    .text {
        max-width: calc(var(--n-container-wdt) / 2 - 20px);
        width: 50vw;
    }
}