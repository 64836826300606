.case {
    border: 1px solid var(--box-shadows);
    border-radius: var(--border-radius);
    display: flex;
    flex-direction: column;
    gap: 6px;
    margin-bottom: 20px;
    padding: 20px;
}

.date {
    opacity: .7;
}

.vin {
    font-size: 18px;
}

.status {
    color: #ccc;
    display: flex;
    gap: 8px;
}

.status i {
    font-style: normal;
}

.status-text,
.status i:last-child {
    color: var(--teal);
}