.top,
.outer,
.bottom {
    align-items: center;
    display: flex;
}

.outer {
    gap: 8px;
    padding-right: 20px;
    margin-bottom: 20px;
    margin-right: -20px;
    overflow-x: auto;
    user-select: none;
}

.outer::-webkit-scrollbar {
    width: 0;
    height: 0;
}

.top,
.bottom {
    background: #fff;
    display: flex;
    flex-wrap: wrap;
    margin-inline: -20px;
    padding-block: 16px;
    position: sticky;
    z-index: 1;
}

.top {
    border-bottom: 1px solid var(--box-shadows);
    justify-content: space-between;
    margin-top: -20px;
    margin-bottom: 20px;
    top: -20px;
}

.bottom {
    bottom: 0;
    border-top: 1px solid var(--box-shadows);
    justify-content: center;
}

.top *,
.bottom * {
    margin: 0 !important;
}

.closed {
    display: none;
    pointer-events: none;
}

.container {
    background: #fff;
    height: 100vh;
    height: 100dvh;
    left: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    padding-inline: var(--container-padding);
    padding-top: var(--container-padding);
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 50;
}

button.btn {
    --height: 40px;
    background: var(--teal);
    border-radius: 8px;
    color: #fff;
    padding: 0 12px;
}